var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-layout",
    { attrs: { view: "hHh lpr fFf", id: "piattaforma-rami" } },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.sta_caricando,
              expression: "sta_caricando",
            },
          ],
          staticClass:
            "text-center justify-center q-pa-md q-gutter-sm fixed-center",
          staticStyle: { "z-index": "1000" },
        },
        [_c("q-spinner-hourglass", { attrs: { size: "4em" } })],
        1
      ),
      _c(
        "q-page-container",
        { staticStyle: { "padding-left": "16px", "padding-right": "16px" } },
        [
          _c("br"),
          _c("div", { staticClass: "row justify-center" }, [
            _vm.isAby
              ? _c(
                  "div",
                  {
                    staticClass: "col-12 col-md-12",
                    attrs: { align: "center" },
                  },
                  [
                    _c("q-img", {
                      staticStyle: { width: "300px" },
                      attrs: { src: _vm.path_logo },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.isAby
              ? _c(
                  "div",
                  {
                    staticClass: "col-12 col-md-12",
                    attrs: { align: "center" },
                  },
                  [_c("h3", [_vm._v(_vm._s(_vm.getRagioneSocialeUfficio))])]
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "col-12 col-md-8" },
              [
                _c("br"),
                _c(
                  "q-toolbar",
                  { staticClass: "bg-primary text-white shadow-2" },
                  [
                    _c("q-toolbar-title", [
                      _c("div", { attrs: { align: "center" } }, [
                        _vm._v("BOX INFORMATIVO"),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "q-list",
                  { attrs: { bordered: "" } },
                  [
                    _c(
                      "q-item",
                      [
                        _c("q-item-label", { staticClass: "text-h5" }, [
                          _vm._v(_vm._s(_vm.getNominativoCliente)),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "q-item",
                      [
                        _c("q-item-label", [
                          _c("strong", [_vm._v("Preventivo N.")]),
                          _vm._v(" " + _vm._s(_vm.getNumeroPreventivo())),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "q-item",
                      [
                        _c("q-item-label", [
                          _c("strong", [_vm._v("Valido fino al ")]),
                          _vm._v(" " + _vm._s(_vm.getDataScadenzaPreventivo)),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "q-expansion-item",
                  {
                    staticClass: "bg-primary text-white text-h6 q-mb-lg",
                    attrs: {
                      "expand-separator": "",
                      label: "IL MIO ASSICURATORE",
                    },
                    model: {
                      value: _vm.mio_assicuratore,
                      callback: function ($$v) {
                        _vm.mio_assicuratore = $$v
                      },
                      expression: "mio_assicuratore",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "text-left",
                        staticStyle: {
                          "background-color": "#fff",
                          color: "#000",
                        },
                      },
                      [
                        _c(
                          "q-list",
                          { attrs: { bordered: "" } },
                          [
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "", lines: "1" } },
                                      [_vm._v("IL MIO CONSULENTE PERSONALE")]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(_vm._s(_vm.getIntermediario)),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "", lines: "1" } },
                                      [_vm._v("LA SUA SEZIONE RUI")]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getRUIIntermediario.numero_rui
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "", lines: "1" } },
                                      [_vm._v("TELEFONO")]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getRecapitiIntermediario.telefono
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "q-item",
                              [
                                _c(
                                  "q-item-section",
                                  [
                                    _c(
                                      "q-item-label",
                                      { attrs: { caption: "", lines: "1" } },
                                      [_vm._v("EMAIL")]
                                    ),
                                    _c("q-item-label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getRecapitiIntermediario.email
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "q-expansion-item",
                  {
                    staticClass: "bg-primary text-white text-h6 q-mb-lg",
                    attrs: {
                      "expand-separator": "",
                      label: "LA PIATTAFORMA ABYWAY",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "text-left",
                        staticStyle: {
                          "background-color": "#fff",
                          color: "#808080",
                          "font-size": "16px",
                          padding: "8px",
                        },
                      },
                      [
                        _vm._v(
                          " La piattaforma ABYWAY opera da oltre 10 anni. Rappresenta un nuovo modo di lavorare in ambito assicurativo ed è divenuta un vero punto di riferimento per centinaia di intermediari di tutta Italia. Attualmente la piattaforma gestisce più di 70.000 clienti "
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "q-expansion-item",
                  {
                    staticClass: "bg-primary text-white text-h6 q-mb-lg",
                    attrs: {
                      "expand-separator": "",
                      label: "IL SISTEMA ABYWAY",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "text-left",
                        staticStyle: {
                          "background-color": "#fff",
                          color: "#808080",
                          "font-size": "16px",
                          padding: "8px",
                        },
                      },
                      [
                        _c("strong", [
                          _vm._v("La rivoluzione degli ultimi 20 anni"),
                        ]),
                        _c("br"),
                        _vm._v(
                          " AbyWay è un sistema operativo evoluto, messo a punto con anni di esperienza. Prevede una gestione molto avanzata del Cliente, attraverso l’elaborazione di questi punti. "
                        ),
                        _c("ul", [
                          _c("li", [
                            _vm._v(
                              "Profilazione del Cliente attraverso la verifica della sua situazione assicurativa. "
                            ),
                          ]),
                          _c("li", [
                            _vm._v(
                              "Comparazione fra un centinaio di tariffe disponibili per la ricerca della migliore soluzione."
                            ),
                          ]),
                          _c("li", [
                            _vm._v(
                              "Scelta della tariffa, adattando l’offerta alle specifiche esigenze del Cliente."
                            ),
                          ]),
                          _c("li", [
                            _vm._v(
                              "In caso di acquisto della polizza il compenso dell’intera struttura è ricavato, in modo del tutto trasparente, dal costo della consulenza assicurativa che viene fattura a parte rispetto al costo della polizza e degli eventuali servizi. "
                            ),
                          ]),
                          _c("li", [
                            _vm._v(
                              "Il risparmio medio per un nuovo cliente è di oltre il 20%!"
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                ),
                _c(
                  "q-expansion-item",
                  {
                    staticClass: "bg-primary text-white text-h6 q-mb-lg",
                    attrs: {
                      "expand-separator": "",
                      label: "DETTAGLIO PROPOSTE",
                    },
                    model: {
                      value: _vm.dettagli_proposte,
                      callback: function ($$v) {
                        _vm.dettagli_proposte = $$v
                      },
                      expression: "dettagli_proposte",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "text-left text-h7",
                        staticStyle: {
                          "background-color": "#fff",
                          color: "#000",
                        },
                      },
                      [
                        _c("br"),
                        _vm._l(
                          _vm.getProdottiSelezionati,
                          function (prodotto, index) {
                            return _c("div", { key: index }, [
                              _c(
                                "fieldset",
                                [
                                  _c("legend", [
                                    _vm._v(_vm._s(prodotto.label)),
                                  ]),
                                  _c("br"),
                                  _c(
                                    "q-list",
                                    {
                                      staticStyle: { "font-size": "16px" },
                                      attrs: { bordered: "" },
                                    },
                                    [
                                      _c(
                                        "q-item",
                                        [
                                          _c(
                                            "q-item-section",
                                            [
                                              _c(
                                                "q-item-label",
                                                {
                                                  attrs: {
                                                    caption: "",
                                                    lines: "1",
                                                  },
                                                },
                                                [_vm._v("FRAZIONAMENTO")]
                                              ),
                                              _c("q-item-label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getFrazionamento(
                                                      prodotto
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item",
                                        [
                                          _c(
                                            "q-item-section",
                                            [
                                              _c(
                                                "q-item-label",
                                                {
                                                  attrs: {
                                                    caption: "",
                                                    lines: "1",
                                                  },
                                                },
                                                [_vm._v("COMPAGNIA")]
                                              ),
                                              _c("q-item-label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm
                                                      .getPropostaSelezionata(
                                                        prodotto
                                                      )
                                                      .compagnia.replaceAll(
                                                        "_",
                                                        " "
                                                      )
                                                  )
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("br"),
                                  _vm.documenti_catalogo.length > 0
                                    ? _c(
                                        "q-list",
                                        {
                                          staticStyle: { "font-size": "16px" },
                                          attrs: { bordered: "" },
                                        },
                                        _vm._l(
                                          _vm.documenti_catalogo[index]
                                            .documenti,
                                          function (
                                            documento_catalogo,
                                            indexCatalogo
                                          ) {
                                            return _c(
                                              "div",
                                              { key: indexCatalogo },
                                              [
                                                _c(
                                                  "q-item",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "ripple",
                                                        rawName: "v-ripple",
                                                      },
                                                    ],
                                                    staticClass: "q-mb-sm",
                                                    staticStyle: {
                                                      "background-color":
                                                        "#ffffc0",
                                                    },
                                                    attrs: { clickable: "" },
                                                  },
                                                  [
                                                    _c(
                                                      "q-item-section",
                                                      {
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.ScaricaDocumento(
                                                              documento_catalogo.path,
                                                              _vm
                                                                .documenti_contratti[
                                                                index
                                                              ].filename
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("q-item-label", [
                                                          _c("strong", [
                                                            _vm._v(
                                                              _vm._s(
                                                                documento_catalogo.descrizione
                                                              )
                                                            ),
                                                          ]),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "q-item-section",
                                                      { attrs: { avatar: "" } },
                                                      [
                                                        _c("q-avatar", {
                                                          attrs: {
                                                            color: "blue-grey",
                                                            "text-color":
                                                              "white",
                                                            icon: "mdi-download",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ])
                          }
                        ),
                      ],
                      2
                    ),
                  ]
                ),
                _c(
                  "q-expansion-item",
                  {
                    staticClass: "bg-primary text-white text-h6 q-mb-lg",
                    attrs: {
                      "expand-separator": "",
                      label: "OFFERTA ECONOMICA",
                    },
                    model: {
                      value: _vm.offerta_economica,
                      callback: function ($$v) {
                        _vm.offerta_economica = $$v
                      },
                      expression: "offerta_economica",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "text-left",
                        staticStyle: {
                          "background-color": "#fff",
                          color: "#000",
                          "font-size": "18px",
                        },
                      },
                      [
                        _c("br"),
                        _c(
                          "table",
                          { staticClass: "table table-bordered" },
                          [
                            _c("thead", [
                              _c("tr", [
                                _c("th", [_vm._v("Settore di polizza")]),
                                _c("th", [_vm._v("Compagnia")]),
                                _c("th", [_vm._v("Premio")]),
                              ]),
                            ]),
                            _vm._l(
                              _vm.getProdottiSelezionati,
                              function (prodotto, index) {
                                return _c("tbody", { key: index }, [
                                  _c("tr", [
                                    _c("td", [_vm._v(_vm._s(prodotto.label))]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm
                                            .getPropostaSelezionata(prodotto)
                                            .compagnia.replaceAll("_", " ")
                                        )
                                      ),
                                    ]),
                                    _c("td", { attrs: { align: "right" } }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getPremioProposta(prodotto)
                                        ) + " €"
                                      ),
                                    ]),
                                  ]),
                                ])
                              }
                            ),
                            _c("tfoot", [
                              _c("tr", [
                                _c(
                                  "td",
                                  { attrs: { colspan: "2", align: "right" } },
                                  [_vm._v("CONSULENZA TOTALE:")]
                                ),
                                _c("td", { attrs: { align: "right" } }, [
                                  _c(
                                    "strong",
                                    { staticStyle: { color: "#800000" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.getConsulenzaTotale()) + " €"
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                              _c("tr", [
                                _c(
                                  "td",
                                  { attrs: { colspan: "2", align: "right" } },
                                  [_c("strong", [_vm._v("TOTALE:")])]
                                ),
                                _c("td", { attrs: { align: "right" } }, [
                                  _c(
                                    "strong",
                                    { staticStyle: { color: "#800000" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.getPremioTotale()) + " €"
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]),
                          ],
                          2
                        ),
                        _c("br"),
                      ]
                    ),
                  ]
                ),
                _c(
                  "q-expansion-item",
                  {
                    staticClass: "bg-primary text-white text-h6 q-mb-lg",
                    attrs: {
                      "expand-separator": "",
                      label: "DOCUMENTI DA LEGGERE",
                    },
                    model: {
                      value: _vm.documenti_leggere,
                      callback: function ($$v) {
                        _vm.documenti_leggere = $$v
                      },
                      expression: "documenti_leggere",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "text-left text-h7",
                        staticStyle: {
                          "background-color": "#fff",
                          color: "#000",
                        },
                      },
                      [
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { bordered: "" } },
                          _vm._l(
                            _vm.documenti_allegati.documenti,
                            function (altro, index) {
                              return _c(
                                "div",
                                { key: index },
                                [
                                  altro.descrizione !==
                                  "dichiarazioniCliente.pdf"
                                    ? _c(
                                        "q-item",
                                        {
                                          directives: [
                                            {
                                              name: "ripple",
                                              rawName: "v-ripple",
                                            },
                                          ],
                                          staticClass: "q-mb-sm",
                                          staticStyle: {
                                            "background-color": "#ffffc0",
                                            "font-size": "18px",
                                          },
                                          attrs: { clickable: "" },
                                        },
                                        [
                                          _c(
                                            "q-item-section",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.ScaricaDocumento(
                                                    altro.path,
                                                    altro.id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(altro.descrizione)
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "q-item-section",
                                            {
                                              attrs: { avatar: "" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.ScaricaDocumento(
                                                    altro.path,
                                                    altro.id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("q-avatar", {
                                                attrs: {
                                                  color: "blue-grey",
                                                  "text-color": "white",
                                                  icon: "mdi-download",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            }
                          ),
                          0
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "q-expansion-item",
                  {
                    staticClass: "bg-primary text-white text-h6 q-mb-lg",
                    attrs: {
                      "expand-separator": "",
                      label: "DOCUMENTI DA SOTTOSCRIVERE",
                    },
                    model: {
                      value: _vm.documenti_sottoscrivere,
                      callback: function ($$v) {
                        _vm.documenti_sottoscrivere = $$v
                      },
                      expression: "documenti_sottoscrivere",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "text-left text-h7",
                        staticStyle: {
                          "background-color": "#fff",
                          color: "#000",
                        },
                      },
                      [
                        _c("br"),
                        _c(
                          "q-list",
                          { attrs: { bordered: "" } },
                          _vm._l(
                            _vm.documenti_allegati.documenti,
                            function (altro, index) {
                              return _c(
                                "div",
                                { key: index },
                                [
                                  altro.descrizione ===
                                  "dichiarazioniCliente.pdf"
                                    ? _c(
                                        "q-item",
                                        {
                                          directives: [
                                            {
                                              name: "ripple",
                                              rawName: "v-ripple",
                                            },
                                          ],
                                          staticClass: "q-mb-sm",
                                          staticStyle: {
                                            "background-color": "#ffffc0",
                                            "font-size": "18px",
                                          },
                                          attrs: { clickable: "" },
                                        },
                                        [
                                          _c(
                                            "q-item-section",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.ScaricaDocumento(
                                                    altro.path,
                                                    altro.id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(altro.descrizione)
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "q-item-section",
                                            {
                                              attrs: { avatar: "" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.ScaricaDocumento(
                                                    altro.path,
                                                    altro.id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("q-avatar", {
                                                attrs: {
                                                  color: "blue-grey",
                                                  "text-color": "white",
                                                  icon: "mdi-download",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            }
                          ),
                          0
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }